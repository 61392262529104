.body{
	height: 200vh;
	background-color: #08569e;
}

.gallery {
	margin: 5px;
	border: 1px solid #ccc;
	float: left;
	width: 20vw;
	height: 60vh;
	background: linear-gradient(
	to right bottom,
	rgba(64, 58, 58, 0.7),
	rgba(64, 58, 58, 0.3)
	);	
	border-radius: 2rem;
	color: rgba(232,239,250,1.00);
	font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
	text-shadow: 0px 0px 5px rgba(47,55,209,1.00);
}

.gallery:hover {
  border: 1px solid #777;
}

.gallery img {
  width: 100%;
  height: 70%;
  object-fit: contain;
  border-radius: 2rem;
}

.desc {
  padding: 15px;
  text-align: center;
  font-size: 3.2vh;
}