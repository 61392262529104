.hero-section {
  background: url('../img/back.png');
  background-size: cover;
  background-position: center;
  height: 65vh; /* Set the desired height for the hero section */
  display: grid;
  align-items: center;
  justify-content: center;
}

.hero-content {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  color: rgb(0, 0, 0);
}
  
.hero-title {
  font-size: 3vw;
  white-space: nowrap;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.hero-subtitle {
  font-size: 1.5vw;
  color: #fff;
  margin-top: 2vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}
/* Style the heading, paragraph, and button as needed */
