.footer {
    background-color: #08569e;
    color: white;
    height: 10vh;
    justify-content: space-between;
    align-items: stretch;
    margin-top: -1.2vh;
    font-size: 0.8vw;
}

.footer h1 {
    text-align: left;
    padding: 0.1% 0.8%;
}

.footer p {
    text-align: right;
    padding: 0.1% 0.8%;
}
