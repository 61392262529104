* {
    box-sizing: border-box;    
}
  
body {
  margin: 0;
}

.container {
  margin: 1rem;
  text-align: center;
}
/*
This is switched off
.navbar{
  width: 100vw;
  height: 12vh;
}
*/
.navbar-container {
  background-color: #08569e;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0.1% 0.8%;
  font-size: 2vw;
}

.site-title {
  font-size: 3.5vw;
}

.navbar-container ul {
  padding: 0.2vh;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1.2vw;
}

.navbar-container a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.navbar-container li.active {
  background-color: #7c7c7c;
}

.navbar-container li:hover {
  background-color: #777;
}


/* NavMenu CSS */
.navbar {
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: center;
}

.menu-button{
    font-size: 5vw;
    margin-right: 2%;
    right: 90%;
    margin-top: 5%;
    color: #08569e;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: #08569e;
}

.nav-menu {
    background-color: #08569e;
    width: 20vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 15%;
    right: -100%;
    transition: 850ms;
}

.nav-menu.active {
    right: 0;
    transition: 450ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2vw 18vw 6vw 6vw;
    list-style: none;
    height: 12%;
}

.nav-text a {
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0vw 0vw;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #ffffff;
    color: #777;
}

.nav-menu-items {
    width: 100vw;
    font-size: 2vw;
}