.body-box{
    align-self: center;
    
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;

    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    background-color: rgb(8,86,158);

}

.body-box h1{
    font-size: 8vh;
    color: white;
    text-align: center;
}

.body-box p{
    font-size: 4vh;
    color: white;
    text-align: center;
}

.body-box a{
    color: white;   
}

