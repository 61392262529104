.BodyBox{
    overflow: auto;
}

.BodyBox h1{
    text-align: center;
    font-size: 5vh;
    /*
    padding: 0.1% 0.8%;

    display: inline;
    */
}

.BodyBox p{
    text-align: center;
    font-size: 3.2vh;
    padding: 0.1% 1%;
}

.BodyBox a{
    text-align: center;
    font-size: 3.2vh;
    padding: 0.1% 1%;

}
